<template>
  <div class="leaving-manage-container">
    <div class="top-container">
      <a-row>
        <a-form-model layout="inline" style="display: flex">
          <a-form-model-item label="留言人">
            <a-input
              placeholder="请输入"
              v-model.trim="queryFrom.contacts"
              style="width: 100%"
              @keyup.enter="queryBtn"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item label="联系电话">
            <a-input
              placeholder="请输入"
              v-model.trim="queryFrom.phone"
              style="width: 100%"
              @keyup.enter="queryBtn"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item label="分类">
            <a-select
              show-search
              placeholder="请选择"
              :getPopupContainer="
                (triggerNode) => handleDislocation(triggerNode)
              "
              :default-active-first-option="false"
              :style="{ width: '28.5rem' }"
              :not-found-content="null"
              v-model="queryFrom.type"
            >
              <a-select-option
                v-for="(item, i) in stateList"
                :value="item.value"
                :key="i"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <div class="right">
            <a-button type="reload" icon="redo" @click="handleReset"
              >重置</a-button
            >
            <a-button type="primary" icon="search" @click="queryBtn"
              >查询</a-button
            >
          </div>
        </a-form-model>
      </a-row>
    </div>
    <div class="bottom-container">
      <a-table
        :columns="columns"
        :data-source="data"
        :key="data.key"
        :loading="loading"
        :scroll="{ y: 450 }"
        :pagination="pagination"
      >
        <template slot="type" slot-scope="text">
          {{ getMenuName(stateList, text) }}
        </template>
      </a-table>
    </div>
    <DetailModal
      :modalVisible="modalVisible"
      v-if="modalVisible"
      :config="configFrom"
    />
  </div>
</template>

<script>
const columns = [
  {
    title: "留言人",
    ellipsis: true,
    dataIndex: "contacts",
  },
  {
    title: "联系电话",
    ellipsis: true,
    dataIndex: "phone",
  },
  {
    title: "分类",
    ellipsis: true,
    dataIndex: "type",
    scopedSlots: { customRender: "type" },
  },
  {
    title: "留言时间",
    ellipsis: true,
    dataIndex: "createTime",
  },
  {
    title: "内容",
    ellipsis: true,
    dataIndex: "suggest",
  },
];

import mixins from "@/mixins/mixins.js";
import pagination from "@/mixins/Pagination.js";
import { API } from "@/apix/leavingMessage.js";
import { getMenuName } from "@/utils/utils.js";

export default {
  mixins: [pagination, mixins],
  components: {},
  data() {
    return {
      columns,
      data: [],
      loading: false,
      stateList: [
        {
          name: "留言",
          value: 0,
        },
        {
          name: "询盘",
          value: 1,
        },
        {
          name: "样品申请",
          value: 2,
        },
      ],
      modalVisible: false,
      queryFrom: {
        contacts: null, // 留言人
        phone: null, // 联系电话
        type: undefined, // 分类
      },
    };
  },
  methods: {
    getMenuName,
    // 获取首页所有字典数据
    getSourceData() {
      let detail = {
        page: this.pagination.current,
        size: this.pagination.pageSize,
        ...this.queryFrom,
      };
      this.loading = true;
      API.pageIndex(detail).then((res) => {
        this.pagination.total = Number(res.data.total);
        this.data = res.data.records.map((i) => {
          i.key = i.id;
          return i;
        });
        this.loading = false;
      });
    },
    // 重置按钮
    handleReset() {
      this.pagination.current = 1;
      Object.keys(this.queryFrom).forEach((key) => {
        this.queryFrom[key] = null;
      });
      this.queryFrom.type = undefined;
      this.getSourceData();
    },
  },
  created() {
    this.getSourceData();
  },
};
</script>

<style lang="scss" scoped>
.top-container {
  height: 10rem;
}
.ant-input {
  width: 28.5rem;
}
.right {
  flex: 1;
  white-space: nowrap;
  text-align: right;
  button {
    margin-right: 2rem;
  }
}
// .bottom-container {
//   min-height: 83%;
// }

.hover {
  margin-right: 2rem;
}

/deep/ .ant-form-item {
  margin-bottom: 0;
}
</style>
